import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

const CONTENT_SETTINGS_COLLECTION = 'contentSettings';
const CURRENT_CONTENT_SETTINGS_VERSION = 'v1';

const converter = {
  toFirestore(
    contentSettings: UserManagement.ContentSettings,
  ): firebase.firestore.DocumentData {
    return withoutNulls(contentSettings);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<UserManagement.ContentSettings>,
    options: firebase.firestore.SnapshotOptions,
  ): UserManagement.ContentSettings {
    if (!snapshot.exists) {
      return {
        mode: 'normal',
      };
    }
    const data = {
      ...snapshot.data(options),
    };

    return data;
  },
};

const getUserContentSettingsRef = ({
  accountId,
  userId,
  db,
}: {
  accountId: string;
  userId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.USERS_COLLECTION)
    .doc(userId)
    .collection(CONTENT_SETTINGS_COLLECTION)
    .doc(CURRENT_CONTENT_SETTINGS_VERSION)
    .withConverter(converter);
};

export default getUserContentSettingsRef;
