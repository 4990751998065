import ALL_SETS_ROUTES from '../screens/DataManager/AllSets/routes';
import isSingleMetricDateMatrix from '../types/visTypeCheckers/isSingleMetricDateMatrix';
import SPECIFIC_SET_ROUTES from '../screens/DataManager/SpecificSet/routes';
import getPortalSlug from './getPortalSlug';
import isPaceMatrix from '../types/visTypeCheckers/isPaceMatrix';
const home = '/';
const logIn = '/logIn';
const resetPassword = '/reset-password';
const welcome = '/welcome';
const acceptInvite = '/invitations/accept';
const expiredInvitation = '/expiredInvitation';
const alreadyAcceptedInvitation = '/alreadyAcceptedInvitation';
const forgotPassword = '/forgot-password';
const wallboardSetup = '/wallboard-setup';
const signOut = '/signOut';
const myAccount = '/my-account';

const reports = '/reports';
const showReport = '/reports/:reportId';
export const buildReportShow = ({
  id,
  isEditing,
  portal,
}: {
  id: string;
  isEditing?: boolean;
  portal: Portal | undefined;
}) =>
  isEditing
    ? `/${getPortalSlug(portal)}reports/${id}?editing=true`
    : `/${getPortalSlug(portal)}reports/${id}`;

const dashboards = '/dashboards';
const showDashboard = '/dashboards/:dashboardId';
export const buildDashboardShow = ({
  id,
  isEditing,
  portal,
}: {
  id: string;
  isEditing?: boolean;
  portal: Portal | undefined;
}) =>
  isEditing
    ? `/${getPortalSlug(portal)}dashboards/${id}?editing=true`
    : `/${getPortalSlug(portal)}dashboards/${id}`;

const shared = '/shared';

// Settings
const settings = '/settings';
export const buildSettings = (portal: Portal | undefined) => {
  return `/${getPortalSlug(portal)}settings`;
};
const allUsersSettings = '/settings/users';
export const buildAllUsersSettings = (portal: Portal | undefined) => {
  return `/${getPortalSlug(portal)}settings/users`;
};
export const buildShowUser = (id: string, portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/users/${id}`;
const showUserSettings = '/settings/users/:userId';
const billingSettings = '/settings/billing';
export const buildBillingSettings = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/billing`;
const integrationsSettings = '/settings/connectors';
export const buildIntegrationsSettings = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/connectors`;
const providerSettings = '/settings/connectors/:provider';
export const buildProviderShow = (
  provider: string,
  portal: Portal | undefined,
) => `/${getPortalSlug(portal)}settings/connectors/${provider}`;
const irGuide = '/settings/connectors/ir-guide';
export const buildIrGuide = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/connectors/ir-guide`;
const ssoConfigurationsSettings = '/settings/sso';
export const buildSsoConfigurationsSettings = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/sso`;
const paceSettings = '/settings/pace';
export const buildPaceSettings = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/pace`;
const microsoftSsoRedirect = '/settings/sso/microsoft_consent_redirect';

const advancedSettings = '/settings/advanced';
export const buildAdvancedSettings = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/advanced`;
const commissions = '/settings/commissions';
const copyContent = '/settings/copy-content';
export const buildCopyContent = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/copy-content`;
const copyContentHistory = '/settings/copy-content-history';
export const buildCopyHistory = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/copy-content-history`;
const localesSettings = '/settings/locale';

// Settings - slas
const allTargetCategories = '/settings/slas';
export const buildAllTargetCategories = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}settings/slas`;
const targetCategoryShow = '/settings/slas/:category';
export const buildTargetCategoryShow = (
  category: string,
  portal: Portal | undefined,
) => `/${getPortalSlug(portal)}settings/slas/${category}`;
const targetShow = '/settings/slas/:category/:target';
export const buildTargetShow = (
  category: string,
  target: string,
  portal: Portal | undefined,
) => `/${getPortalSlug(portal)}settings/slas/${category}/${target}`;

// Goals
const goals = '/goals';
const showGlobalGoal = '/goals/:goalId';
export const buildShowGlobalGoal = (goalId: string) => `/goals/${goalId}`;
const kpiLists = '/kpi-lists';
const showGlobalKpiList = '/kpi-lists/:scorecardId';
export const buildShowGlobalKpiList = ({
  scorecardId,
  kpiId,
  periodEnd,
  periodStart,
  portal,
}: {
  scorecardId: string;
  kpiId?: string;
  periodStart?: string;
  periodEnd?: string;
  portal?: Portal | undefined;
}) => {
  if (kpiId && periodStart && periodEnd) {
    return `/${getPortalSlug(portal)}kpi-lists/${scorecardId}?kpiId=${kpiId}&periodStart=${periodStart}&periodEnd=${periodEnd}`;
  }

  return `/${getPortalSlug(portal)}kpi-lists/${scorecardId}`;
};
const showCampaignGoal = '/workspaces/:workspaceId/goals/:goalTabId/:goalId';
export const buildShowCampaignGoal = (
  goalId: string,
  campaignId: string,
  goalsTabId: string,
) => `/workspaces/${campaignId}/goals/${goalsTabId}/${goalId}`;
const showCampaignKpiList =
  '/workspaces/:workspaceId/kpi-lists/:scorecardTabId/:scorecardId';

export const getGadgetLink = (
  chart: V5ChartDefinition,
  portal: Portal | undefined,
) => {
  if (isSingleMetricDateMatrix(chart) || isPaceMatrix(chart)) {
    return buildGadgetShow(chart.id, portal);
  } else {
    return buildV5GadgetShow(chart.id, portal);
  }
};

const v5GadgetBuilder = '/gadgets';
export const buildV5GadgetBuilder = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}gadgets`;
const v5GadgetBuilderShow = '/gadgets/v5/:gadgetId';
export const buildV5GadgetShow = (id: string, portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}gadgets/v5/${id}`;
const gadgetBuilderNew = '/gadgets/new';
const gadgetBuilderShow = '/gadgets/:gadgetId';
export const buildGadgetShow = (id: string, portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}gadgets/${id}`;

const showOrderDetails = '/details/:orderNumber';
export const buildShowOrderDetails = (id: string, portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}details/${id}`;

const boards = '/boards';
const showBoard = '/boards/:boardId';
export const buildShowBoard = (id: string) => `/boards/${id}`;
export const buildCustomerLaneSlideOutSearchQuery = ({
  lane,
  customer,
  customerType,
  documentId,
}: {
  lane: string;
  customer: string;
  customerType: string;
  documentId: string;
}) => {
  if (customerType === 'customer') {
    return `lane=${lane}&customerType=${customerType}&customer=${customer}&documentId=${documentId}`;
  } else {
    return `lane=${lane}&customerType=${customerType}&billTo=${customer}&documentId=${documentId}`;
  }
};
export const buildCustomerLaneSlideOutSearch = ({
  boardId,
  lane,
  customer,
  customerType,
  documentId,
}: {
  boardId: string;
  lane: string;
  customer: string;
  customerType: string;
  documentId: string;
}) =>
  `${buildShowBoard(boardId)}?${buildCustomerLaneSlideOutSearchQuery({
    lane,
    customer,
    customerType,
    documentId,
  })}`;

const wallBoardConfigs = '/wallBoardConfig';
const showWallBoardConfig = `/wallBoardConfig/:wallBoardId`;
export const buildShowWallBoardConfig = (
  id: string,
  portal: Portal | undefined,
) => `/${getPortalSlug(portal)}wallBoardConfig/${id}`;

const showWallBoard = `/wallBoard/:wallBoardId`;
export const buildShowWallBoard = (id: string, portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}wallBoard/${id}`;

// Workspaces
const workspaces = '/workspaces';
const showWorkSpace = `/workspaces/:workSpaceId`;
const showWorkSpaceTab = `/workspaces/:workSpaceId/:tabType/:tabId`;
export const buildShowWorkSpace = (id: string) => `/workspaces/${id}`;
export const buildShowWorkSpaceTab = ({
  workSpaceId,
  tabType,
  tabId,
}: {
  workSpaceId: string;
  tabType: string;
  tabId?: string;
}) => `/workspaces/${workSpaceId}/${tabType}/${tabId}`;

// Performance Apps
const performanceApps = '/performance-apps';
const showPerformanceApp = `/performance-apps/:workSpaceId`;
const showPerformanceAppTab = `/performance-apps/:workSpaceId/:tabType/:tabId`;
export const buildShowPerformanceApp = (
  id: string,
  portal: Portal | undefined,
) => `/${getPortalSlug(portal)}performance-apps/${id}`;
export const buildShowPerformanceAppTab = ({
  workSpaceId,
  tabType,
  tabId,
  portal,
}: {
  workSpaceId: string;
  tabType: string;
  tabId: string;
  portal?: Portal | undefined;
}) =>
  `/${getPortalSlug(portal)}performance-apps/${workSpaceId}/${tabType}/${tabId}`;

// Target Apps
const showTargetApp = `/target-apps/:targetAppId`;
const showTargetAppTasksTab = `/target-apps/:targetAppId/tasks`;
const showTargetAppContentTab = `/target-apps/:targetAppId/:tabType/:tabId`;
export const buildShowTargetApp = (id: string, portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}target-apps/${id}`;
const buildShowTargetAppTasksTab = (id: string, portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}target-apps/${id}/tasks`;
export const buildShowTargetAppContentTab = ({
  targetAppId,
  tabType,
  tabId,
  portal,
}: {
  targetAppId: string;
  tabType: string;
  tabId?: string;
  portal: Portal | undefined;
}) => {
  if (tabType === 'tasks') {
    return buildShowTargetAppTasksTab(targetAppId, portal);
  }
  return `/${getPortalSlug(portal)}target-apps/${targetAppId}/${tabType}/${tabId}`;
};

// Entity Details
const entityAppRedirect = `/entity/:entityId`;
export const buildEntityAppRedirect = ({
  entityId,
  portal,
}: {
  entityId: string;
  portal?: Portal | undefined;
}) => {
  return `/${getPortalSlug(portal)}entity/${entityId}`;
};
/**
 * Be careful changing this and showEntityAppContentTab
 * This is tightly coupled in {@link useBuildEntityLink} without typescript support
 */
const showEntityApp = `/entities/:field/:value`;
const showEntityAppContentTab = `/entities/:field/:value/:tabType/:tabId`;
export const buildShowEntityApp = ({
  field,
  value,
  portal,
}: {
  field: string;
  value?: string;
  portal?: Portal;
}) => {
  if (!value || value === '') {
    return `/${getPortalSlug(portal)}entities/${field}`;
  }

  return `/${getPortalSlug(portal)}entities/${field}/${encodeURIComponent(value)}`;
};
export const buildShowEntityAppContentTab = ({
  field,
  value,
  tabType,
  tabId,
  portal,
}: {
  field: string;
  value?: string;
  tabType: string;
  tabId: string;
  portal?: Portal;
}) => {
  if (!value || value === '') {
    return `/${getPortalSlug(portal)}entities/${field}/${tabType}/${tabId}`;
  }

  return encodeURI(
    `/${getPortalSlug(portal)}entities/${field}/${encodeURIComponent(value)}/${tabType}/${tabId}`,
  );
};

const showEntityAppUnfiltered = `/entities/:field`;
const showEntityAppContentTabUnfiltered = `/entities/:field/:tabType/:tabId`;

const driverBonus = '/driverBonus';

const datasetDefinitions = {
  home: ALL_SETS_ROUTES.BASE,
  dataset: SPECIFIC_SET_ROUTES.BASE,
  field: `${SPECIFIC_SET_ROUTES.BASE}?field=:field`,
  buildShowDataset: SPECIFIC_SET_ROUTES.buildFieldSettings,
  buildShowField: (type: string, field: string, portal: Portal | undefined) =>
    `${SPECIFIC_SET_ROUTES.buildFieldSettings(type, portal)}?field=${field}`,
};

// On-boarding
const onBoardingLanding = '/';
const onBoardingBilling = '/billing';
const onBoardingIrGuide = '/ir-guide';
const onBoardingProviderSettings = '/connectors/:provider';

export const buildOnboardingProviderShow = (provider: string) =>
  `/connectors/${provider}`;

const engagementPortalAccess = '/access/:type';

/**
 * There are our routes for the legacy UI, the admin portal, onboarding and unauthenticated
 * When we stop supporting the legacy UI, lets consider breaking this file up into
 * a number of files, one for each area of concern.
 *
 * Additionally, the admin portal routes ought to be prefixed with /admin
 *
 * I opted not to do it during the initial development to save time
 */
const AppRoutes = {
  home,
  logIn,
  welcome,
  acceptInvite,
  forgotPassword,
  expiredInvitation,
  alreadyAcceptedInvitation,
  resetPassword,
  wallboardSetup,
  onBoarding: {
    landing: onBoardingLanding,
    billing: onBoardingBilling,
    provider: onBoardingProviderSettings,
    irGuide: onBoardingIrGuide,
  },
  loggedIn: {
    reports,
    showReport,
    dashboards,
    showDashboard,
    signOut,
    myAccount,
    allUsersSettings,
    showUserSettings,
    localesSettings,
    allTargetCategories,
    targetCategoryShow,
    targetShow,
    settings,
    billingSettings,
    v5GadgetBuilder,
    v5GadgetBuilderShow,
    gadgetBuilderNew,
    gadgetBuilderShow,
    integrationsSettings,
    irGuide,
    providerSettings,
    showOrderDetails,
    boards,
    showBoard,
    wallBoardConfigs,
    showWallBoardConfig,
    showWallBoard,
    workspaces,
    shared,
    showWorkSpace,
    showWorkSpaceTab,
    performanceApps,
    showPerformanceApp,
    showPerformanceAppTab,
    showTargetApp,
    showTargetAppTasksTab,
    showTargetAppContentTab,
    entityAppRedirect,
    showEntityApp,
    showEntityAppContentTab,
    showEntityAppUnfiltered,
    showEntityAppContentTabUnfiltered,
    driverBonus,
    goals,
    showCampaignGoal,
    showCampaignKpiList,
    showGlobalGoal,
    datasetDefinitions,
    advancedSettings,
    commissions,
    kpiLists,
    showGlobalKpiList,
    ssoConfigurationsSettings,
    microsoftSsoRedirect,
    paceSettings,
    copyContent,
    copyContentHistory,
    engagementPortalAccess,
  },
};

export default AppRoutes;
