import React from 'react';

interface FilterPlatesContextType {
  drillDowns: FilterPlateType[];
  setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlateType[]>>;
  drillDownsAsFixed: FixedFilter[];
  scope: FilterPlateType[];
  setScope: React.Dispatch<React.SetStateAction<FilterPlateType[]>>;
  scopeAsFixed: FixedFilter[];
  dataTypes?: string[];
  quickFilters: FilterInput[];
  isSavedFiltersSelectorDisabled?: boolean;
  isDefaultFiltersDisabled?: boolean;
  setIsDefaultFiltersDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  fieldRedList?: string[];
}

const FilterPlatesContext = React.createContext<FilterPlatesContextType>({
  drillDowns: [] as FilterPlateType[],
  scope: [] as FilterPlateType[],
  quickFilters: [] as FilterInput[],
  scopeAsFixed: [] as FixedFilter[],
  drillDownsAsFixed: [] as FixedFilter[],
  fieldRedList: [] as string[],
} as FilterPlatesContextType);

export default FilterPlatesContext;
