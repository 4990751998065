import { useContext, useEffect, useState } from 'react';
import CustomerLaneCommitsContext from '../contexts/CustomerLaneCommitsContext';
import useCommitment from './useCommitment';
import AccountContext from '../contexts/AccountContext';

export const DEMO_5_DATE = '2021-11-27';
export const DEMO_6_DATE = '2024-01-07';

const DEFAULT_FI = {
  keywords: [],
  ranges: [],
  booleanFilters: [],
  wildcardFilters: [],
} as FilterInput;

const DEFAULT_DATE_SCOPE = {};

const useBoardMetricFilter = (
  commitmentId?: string,
  isPerformanceGrid?: boolean,
): {
  isLoading: boolean;
  dateScope: DateRangeInput;
  filterInput?: FilterInput;
} => {
  const { isDemoAccount, isDemo6 } = useContext(AccountContext);
  const commitment = useCommitment(commitmentId);
  const { selectedLane } = useContext(CustomerLaneCommitsContext);
  const [filterInput, setFilterInput] = useState<FilterInput>(DEFAULT_FI);
  const [dateScope, setDateScope] =
    useState<DateRangeInput>(DEFAULT_DATE_SCOPE);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (commitment && selectedLane) {
      const { customer, lane, billTo } = commitment;

      const origin = (() => {
        if (lane.originRegion) {
          return { field: 'originRegion', values: [lane.originRegion] };
        }
        if (lane.originState) {
          return { field: 'originState', values: [lane.originState] };
        }
        if (lane.startCityAndState) {
          return {
            field: 'startCityAndState',
            values: [lane.startCityAndState],
          };
        }
        if (lane.pickUpArea) {
          return { field: 'pickUpArea', values: [lane.pickUpArea] };
        }
        return undefined;
      })();
      const destination = (() => {
        if (lane.destinationRegion) {
          return {
            field: 'destinationRegion',
            values: [lane.destinationRegion],
          };
        }
        if (lane.destinationState) {
          return { field: 'destinationState', values: [lane.destinationState] };
        }
        if (lane.finishCityAndState) {
          return {
            field: 'finishCityAndState',
            values: [lane.finishCityAndState],
          };
        }
        if (lane.destinationArea) {
          return { field: 'destinationArea', values: [lane.destinationArea] };
        }
        return undefined;
      })();

      const fi = {
        keywords: [
          {
            field: customer ? 'customer' : 'billTo',
            values: [customer ? customer : billTo],
          },
        ],
        ranges: [],
        booleanFilters: [],
        wildcardFilters: [],
      } as FilterInput;
      if (isPerformanceGrid && commitment.startDate) {
        if (isDemoAccount) {
          setDateScope({ startDate: isDemo6 ? DEMO_6_DATE : DEMO_5_DATE });
        } else {
          setDateScope({ startDate: commitment.startDate });
        }
      } else {
        if (isDemoAccount) {
          setDateScope({ startDate: isDemo6 ? DEMO_6_DATE : DEMO_5_DATE });
        } else {
          setDateScope(DEFAULT_DATE_SCOPE);
        }
      }

      if (!fi.keywords) {
        fi.keywords = [];
      }
      if (isPerformanceGrid) {
        fi.keywords.push({
          field: 'configId',
          values: commitmentId ? [commitmentId] : [],
        });
        fi.keywords.push({ field: 'lane', values: [selectedLane] });
      } else {
        if (origin) {
          fi.keywords.push(origin);
        }
        if (destination) {
          fi.keywords.push(destination);
        }
      }

      if (!isPerformanceGrid) {
        if (isDemoAccount) {
          setDateScope({
            startDate: commitment.startDate,
            endDate: isDemo6 ? DEMO_6_DATE : DEMO_5_DATE,
          });
        } else {
          setDateScope({
            startDate: commitment.startDate,
            endDate: commitment.endDate,
          });
        }
      }

      setFilterInput(fi);
      setIsLoading(false);
    } else {
      setFilterInput(DEFAULT_FI);
      setIsLoading(true);
    }
  }, [
    commitment,
    commitmentId,
    isDemo6,
    isDemoAccount,
    isPerformanceGrid,
    selectedLane,
  ]);

  return {
    filterInput,
    dateScope,
    isLoading,
  };
};

export default useBoardMetricFilter;
