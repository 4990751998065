import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import React, { lazy, useState } from 'react';
import appRoutes from './appRoutes';
import TargetManager from '../components/TargetManager';
import AppRoutes from './appRoutes';

const SharedIndex = lazy(() => import('../screens/SharedIndex'));
const WorkSpacesIndex = lazy(() => import('../screens/WorkSpacesIndex'));
const WorkSpacesShow = lazy(() => import('../screens/WorkSpacesShow'));
const DashboardsIndex = lazy(() => import('../screens/Dashboards'));
const DashboardShow = lazy(() => import('../screens/DashboardShow'));
const ReportsIndex = lazy(() => import('../screens/Reports'));
const ReportShow = lazy(() => import('../screens/ReportShow'));
const ScorecardsIndex = lazy(() => import('../screens/ScorecardsIndex'));
const ScorecardShow = lazy(() => import('../screens/ScorecardShow'));
const EntityDetailsShow = lazy(() => import('../screens/EntityDetailsShow'));
const EntityDetailsShowRedirect = lazy(
  () => import('../screens/EntityDetailsShowRedirect'),
);
const EngagementPortalHome = lazy(
  () => import('../screens/EngagementPortalHome'),
);
const EngagementPortalUsers = lazy(
  () => import('../screens/EngagementPortalUsers'),
);
const DetailsScreen = lazy(() => import('../screens/OrderDetails'));

const TargetManagerScreen = () => {
  const { dataset } = useParams<{ dataset: string }>();
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  if (dataset === undefined) {
    navigate(AppRoutes.home);
    return null;
  }

  return (
    <TargetManager
      dataset={dataset}
      hasUnsavedChanges={hasUnsavedChanges}
      setHasUnsavedChanges={setHasUnsavedChanges}
    />
  );
};

const EngagementPortalsSwitch = ({ portal }: { portal: EngagementPortal }) => {
  return (
    <Routes>
      <Route path={''} element={<EngagementPortalHome />} />
      <Route path={appRoutes.loggedIn.shared} element={<SharedIndex />} />
      <Route
        path={appRoutes.loggedIn.workspaces}
        element={<WorkSpacesIndex />}
      />
      <Route
        path={appRoutes.loggedIn.showWorkSpace}
        element={<WorkSpacesShow />}
      />
      <Route
        path={appRoutes.loggedIn.showWorkSpaceTab}
        element={<WorkSpacesShow />}
      />
      <Route
        path={appRoutes.loggedIn.dashboards}
        element={<DashboardsIndex />}
      />
      <Route
        path={appRoutes.loggedIn.showDashboard}
        element={<DashboardShow />}
      />
      <Route path={appRoutes.loggedIn.reports} element={<ReportsIndex />} />
      <Route path={appRoutes.loggedIn.showReport} element={<ReportShow />} />
      <Route path={appRoutes.loggedIn.kpiLists} element={<ScorecardsIndex />} />
      <Route
        path={appRoutes.loggedIn.showGlobalKpiList}
        element={<ScorecardShow />}
      />
      <Route path={'/targets/:dataset'} element={<TargetManagerScreen />} />
      <Route
        path={appRoutes.loggedIn.entityAppRedirect}
        element={<EntityDetailsShowRedirect />}
      />
      <Route
        path={appRoutes.loggedIn.showEntityAppUnfiltered}
        element={<EntityDetailsShow />}
      />
      <Route
        path={appRoutes.loggedIn.showEntityAppContentTabUnfiltered}
        element={<EntityDetailsShow />}
      />
      <Route
        path={appRoutes.loggedIn.showEntityApp}
        element={<EntityDetailsShow />}
      />
      <Route
        path={appRoutes.loggedIn.showEntityAppContentTab}
        element={<EntityDetailsShow />}
      />
      <Route
        path={appRoutes.loggedIn.showOrderDetails}
        element={<DetailsScreen />}
      />
      <Route
        path={appRoutes.loggedIn.engagementPortalAccess}
        element={<EngagementPortalUsers portal={portal} />}
      />
    </Routes>
  );
};

export default EngagementPortalsSwitch;
